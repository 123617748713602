import { Anchor, Box, Footer, generate, grommet, Grommet, Header, Heading, Nav, Text } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { Router } from 'preact-router';
import Home from '../routes/home';


const App = () => (
	<Grommet full theme={deepMerge(deepMerge(generate(20), grommet), {
		global: {
			colors: {
				brand: "#32454b",
				background: "light-2"
			}
		}
	})}>
		<Box fill>
			<Header background="brand" pad="small" elevation="small">
				<Heading margin="none" level={3}>char</Heading>
				<Nav>
					<Anchor label="home" />
				</Nav>
			</Header>
			<Box fill>
				<Router>
					<Home path="/" />
				</Router>
			</Box>
			<Footer border={{ side: "top", color: "light-3" }} background="light-1" justify="center" pad="small" >
				<Box width="xlarge" align="end" >
					<Text weight="bold" color="dark-3" size="small">char.com.br</Text>
				</Box>
			</Footer>
		</Box>
	</Grommet >
)

export default App;
